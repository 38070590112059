<template>
    <v-container fill-height>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="12">
                <v-card class="mx-auto pt-3" max-width="600">
                    <v-img
                        class="white--text align-end"
                        width="600px"
                        src="@/assets/img/nodata.png"
                    ></v-img>
                    <v-card-title class="pb-0 justify-center text-h5 text-md-h3"
                        >4 0 4</v-card-title
                    >
                    <v-card-title class="pt-0 justify-center text-h5 text-md-h3"
                        >NOT FOUND</v-card-title
                    >
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {};
</script>

<style></style>
